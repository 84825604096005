@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: DM Sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-DMSans{
  font-family: DM Sans !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px #163545; 
  border-radius: 1px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #163545; 
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #163545; 
}

.app-bar{
  height: 90px !important;
  line-height: 90px !important;
}

.brandingPatternBg {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  /* background-image: url(../../assets/TrestleBgPattern.svg); */
  background: linear-gradient(157.73deg,
      rgba(162, 240, 236, 0) 31.39%,
      #e5f4f7 60.56%);
  background-size: cover;
  padding: 16px 16px;
}

@media (max-width: 640px) {
  .brandingPatternBg {
    min-height: fit-content;
  }
}

.rdrDefinedRangesWrapper {
  display: none !important;
}

.rdrMonthsVertical {
  flex-direction: row !important;
}

.css-vsiucd-MuiButtonBase-root-MuiAccordionSummary-root.Mui-focusVisible {
  background-color: transparent !important;
}

.menu-hover.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: #1d4e5ab2 !important;
  color: white !important;
}

.dialogInvitation .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 700px !important;
  width: 100% !important;
}

.otp-input {
  width: 80px !important;
  height: 80px !important;
}

@media (max-width: 1280px) {
  .otp-input {
    width: 32px !important;
    height: 32px !important;
  }
}

.brandingPageText {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  width: 476px;
  color: #00485a;
}

@media (max-width: 1250px) {
  .brandingPageText {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    width: fit-content;
  }
}

.testRequestContainer {
  display: flex;
  align-items: center;
  padding: 11px 30px 11px 30px;
  gap: 10px;
  width: 100%;
  height: 40px;
  background: #1635450d;
}

.brandingPageSubText {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #00485a;
}

@media (max-width: 1250px) {
  .brandingPageSubText {
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
  }
}

.listItemText {
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: -0.01em;
  text-align: center;
  width: 100%;
  text-wrap: wrap;
}

/* .trestle-drawer .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  border: none !important;
  min-width: 315px !important;
  width: 315px !important;
}

.trestle-drawer-fit .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  border: none !important;
  width: 90px !important;
}

.trestle-drawer-fit .css-109nrww {
  border: none !important;
  width: 90px !important;
}

.trestle-drawer-fit .MuiDrawer-paper{
  border: none !important;
  width: 90px !important;
}

.MuiDrawer-paper{
  border: none !important;
}

.trestle-drawer .css-109nrww {
  border: none !important;
  width: 90px !important;
}

.css-1mq14yt {
  border: none !important;
  min-width: 315px !important;
  width: 315px !important;
}

.trestle-drawer .css-1l8j5k8 {
  border: none !important;
} */

/* .loader-dialog .css-10d30g3-MuiPaper-root-MuiDialog-paper {
  background: transparent !important;
  overflow: hidden !important;
  box-shadow: none !important;
  color: #006d88 !important;
} */

/* .loader-dialog .css-uhb5lp {
  background: transparent !important;
  overflow: hidden !important;
  box-shadow: none !important;
  color: #006d88 !important;
} */

.Mui-selected {
  background-color: #1a4753 !important;
  color: #f3f3f3 !important;
}

.select-dropdown .css-13cymwt-control {
  background: #F3F5F6 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.css-13cymwt-control:focus {
  background: #F3F5F6 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.css-13cymwt-control:hover {
  background: #F3F5F6 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.css-t3ipsp-control {
  background: #F3F5F6 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.css-t3ipsp-control:hover {
  background: #F3F5F6 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.css-1nmdiq5-menu {
  position: absolute !important;
  z-index: 999999 !important;
}